import { useFormContext } from 'react-hook-form';
import React from 'react';

import { Card, CardBody, CardHeader, Label } from 'components';
import { InputForm, CustomSelectForm } from 'components/HookForm';

const KLUsersFormFields = ({ title = '', directorateOptions = [] }) => {
  const { register } = useFormContext();

  return (
    <Card>
      <CardHeader>
        <div>{title}</div>
      </CardHeader>
      <CardBody>
        <div className="space-y-2 flex-1 mb-4">
          <Label required>Nama Lengkap</Label>
          <InputForm
            className="w-full"
            {...register('fullname', { required: true })}
          />
        </div>
        <div className="space-y-2 flex-1 mb-4">
          <Label required>NIK</Label>
          <InputForm
            className="w-full"
            {...register('nik', { required: true })}
          />
        </div>
        <div className="space-y-2 flex-1 mb-4">
          <Label required>Email</Label>
          <InputForm
            className="w-full"
            type="email"
            {...register('email', { required: true })}
          />
        </div>
        <div className="space-y-2 flex-1 mb-4">
          <Label required>Jabatan</Label>
          <InputForm
            className="w-full"
            {...register('jabatan', { required: true })}
          />
        </div>
        <div className="space-y-2 flex-1 mb-4">
          <Label required>Direktorat</Label>
          <CustomSelectForm
            className="w-full"
            options={directorateOptions}
            {...register('directorat', { required: true })}
          />
          {/* <InputForm
            className="w-full"
            {...register("directorat", { required: true })}
          /> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default KLUsersFormFields;
