import React from "react";
import { useFormContext } from "react-hook-form";

import { Card, CardBody, CardHeader, Label } from "components";
import GroupForm from "./groupForm";
import PopupSelectUserInternal from "./popupSelectUserInternal";
import PopupSelectUserKL from "./popupSelectUserKL";

const TopFormList = ({ title = "Tambah Data" }) => {
  const { getValues, setValue, watch } = useFormContext();

  const userTypes = ["internal", "kl"];

  const userType = watch("groupUserType");
  const usersInGroup = watch("groupUsers", []);

  const handleSelectUser = (newSelectedUsers) => {
    const currentUsersInGroup = getValues("groupUsers");
    const currentUsersInGroupIds = currentUsersInGroup.map(({ code }) => code);

    setValue("groupUsers", [
      ...currentUsersInGroup,
      // only push users not in group
      ...newSelectedUsers.filter(
        ({ code }) => !currentUsersInGroupIds.includes(code)
      ),
    ]);
  };

  return (
    <div className="space-y-6 pt-8">
      <Card>
        <CardHeader>
          <div>{title}</div>
        </CardHeader>
        <CardBody>
          <GroupForm />
          <div className="mt-3 form-group space-y-2">
            <Label required>Group User</Label>
            <PopupSelectUserInternal onSubmit={handleSelectUser} />
            <PopupSelectUserKL className="ms-3" onSubmit={handleSelectUser} />
          </div>
        </CardBody>
      </Card>

      <div className="tabs flex-nowrap">
        {userTypes.map((type) => {
          const classNameActive =
            type === userType
              ? "tab-active !border-primary-600 text-primary-600"
              : "";

          const label = type === "kl" ? "Kementrian Lembaga" : type;

          const userCount = usersInGroup.filter(
            (user) => user.type === type
          ).length;

          return (
            <div
              key={type}
              className={`tab w-full tab-bordered capitalize ${classNameActive}`}
              onClick={() => setValue("groupUserType", type)}
            >
              {`User ${label}`}

              {!!userCount && (
                <span className="badge badge-sm badge-neutral ms-2">
                  {userCount}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopFormList;
