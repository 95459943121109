import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const LevelForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Group User Name</Label>
        <InputForm
          className="w-full"
          {...register("name", { required: true })}
        />
      </div>
    </div>
  );
};

export default LevelForm;
