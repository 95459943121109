import { createContext, useState } from "react";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState("");
  const [toastDescription, setToastDescription] = useState("");
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);

  const showToastMessage = (message, description, type) => {
    setToastMessage(message);
    setToastDescription(description);
    setToastType(type);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 7000);
  };

  const hideToastMessage = () => {
    setShowToast(false);
  };

  return (
    <ToastContext.Provider
      value={{
        toastMessage,
        toastDescription,
        showToast,
        toastType,
        showToastMessage,
        hideToastMessage,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
