import React from "react";

const My404Component = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <p className="text-4xl font-bold">404 Not Found</p>
    </div>
  );
};

export default My404Component;
