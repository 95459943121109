import KLUsers from "pages/KLUsers";
import KLUsersForm from "pages/KLUsers/Form";
import KLUsersFormEdit from "pages/KLUsers/FormEdit";

const listKLUsers = [
  {
    routeType: "private",
    path: "/list-kl-users",
    element: KLUsers,
    exact: true,
    accessRight: {
      id: "user-kl",
      type: "canView",
    },
  },
  {
    routeType: "private",
    path: "/list-kl-users/form",
    element: KLUsersForm,
    exact: true,
    accessRight: {
      id: "user-kl",
      type: "canCreate",
    },
  },
  {
    routeType: "private",
    path: "/list-kl-users/form/edit",
    element: KLUsersFormEdit,
    exact: true,
    accessRight: {
      id: "user-kl",
      type: "canUpdate",
    },
  },
];

export default listKLUsers;
