import React from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { Spinner, Pagination } from "components";

const CustomTable = ({
  columns = [],
  dataSource = [],
  isLoading = false,
  stripped = false,
  wrapperClassName = "",
  headerClassName = "",
  className = "",
  layout = "auto",
  defaultSortOrder = undefined,
  pagination = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
}) => {
  return (
    <div className="border-[1px] border-solid border-gray-200 rounded-md">
      <div
        className={classNames(
          ["overflow-x-auto", "overflow-y-hidden"],
          [...wrapperClassName.split(" ")]
        )}
      >
        <table
          className={classNames(
            ["table min-w-full"],
            { "table-auto": layout === "auto" },
            { "table-fixed": layout === "fixed" },
            {
              "table-striped": stripped,
            },
            [...className.split(" ")]
          )}
        >
          {layout === "fixed" && <TableColumn items={columns} />}
          <TableHeader
            items={columns}
            headerClassName={headerClassName}
            defaultSortOrder={defaultSortOrder}
          />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-100 h-[300px]">
                    <Spinner className="text-xl" />
                  </div>
                </td>
              </tr>
            ) : (
              <TableRow columns={columns} datas={dataSource} />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pagination={pagination}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
      />
    </div>
  );
};

export default React.memo(CustomTable);
