import { Breadcrumbs, Button, SearchInput, TitleText, Table } from "components";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const BasicList = ({
  links = [],
  title = "",
  columns = [],
  dataSource = [],
  isLoading = false,
  onCreate,
  onSearch,
  onChangePage,
  onChangeRowsPerPage,
  pagination = {},
  customFilter = null,
  topFormList = null
}) => {
  return (
    <div>
      <Breadcrumbs items={links} />
      {topFormList ?? null}
      <div className="md:flex block items-center my-3">
        <TitleText className="flex-1">{title}</TitleText>
        {Boolean(onCreate) && (
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            startIcon={<AiOutlinePlus className="text-white" />}
            onClick={onCreate}
          >
            Tambah Data
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {customFilter ? (
              React.cloneElement(customFilter)
            ) : (
              <div className="space-x-5 flex">
                <SearchInput placeholder="Cari" onChange={onSearch} />
              </div>
            )}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={columns}
              dataSource={dataSource}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={pagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicList;
