import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ToastContext } from "providers/toastProvider";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";

const Toast = ({ type = "success", onClose }) => {
  const {
    showToast,
    toastType,
    toastMessage,
    toastDescription,
    hideToastMessage,
  } = useContext(ToastContext);

  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    hideToastMessage();
  };

  if (!showToast) return <></>;

  if ((toastType ?? type) === "success") {
    return (
      <SuccessToast
        message={toastMessage}
        message_description={toastDescription}
        onClose={handleClose}
      />
    );
  }

  return (
    <ErrorToast
      message={toastMessage}
      message_description={toastDescription}
      onClose={handleClose}
    />
  );
};

Toast.propTypes = {
  type: PropTypes.oneOf(["success", "error"]),
  onClose: PropTypes.func,
};

export default Toast;

export { SuccessToast, ErrorToast };
