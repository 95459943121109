import { RiUserLine } from "react-icons/ri";
import { FaRankingStar, FaPeopleLine } from "react-icons/fa6";

const MENU_ITEMS = [
  {
    icon: (
      <RiUserLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "list-internal-users",
    label: "Data User Internal",
    path: "/list-internal-users",
    type: "canView",
    child: []
  },
  {
    icon: (
      <RiUserLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "user-kl",
    label: "Data User Kementrian Lembaga",
    path: "/list-kl-users",
    type: "canView",
    child: []
  },
  {
    icon: (
      <FaRankingStar className="text-white w-[18px] h-[20px]" />
    ),
    id: "level-management",
    label: "Manajemen Level",
    path: "/level-management",
    type: "canView",
    child: []
  },
  {
    icon: (
      <FaPeopleLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "group-management",
    label: "Manajemen Group",
    path: "/group-management",
    type: "canView",
    child: []
  },
];

export default MENU_ITEMS;