import axios from "axios";
import { config } from "app/config";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getListLevel= (params) => {
  return axios.post(
    `${config.BASE_URL}/Level/datatable`,
    params,
    { headers }
  );
};

export const postLevel= (params) => {
  return axios.post(
    `${config.BASE_URL}/Level`,
    params,
    { headers }
  );
};

export const getLevelDetail= (params) => {
  return axios.get(
    `${config.BASE_URL}/Level/${params}`,
    { headers }
  );
};

export const getLevelUserList= () => {
  return axios.post(
    `${config.BASE_URL}/User/Level`,
    { headers }
  );
};

export const getDetailPrivilage= (params) => {
  return axios.get(
    `${config.BASE_URL}/Level/privilege/${params}`,
    { headers }
  );
};


export const updateDetailPrivilage = (params) => {
  console.log(params)
  return axios.put(
    `${config.BASE_URL}/Level/privilege`,
    params,
    {
      headers,
    }
  );
};

export const updateLevelDetail = (params) => {
  return axios.put(`${config.BASE_URL}/Level`, params, {
    headers,
  });
};