import { useFormContext } from "react-hook-form";
import React, { useState } from "react";

const TableModuleAccess = ({ data = [] }) => {
  const { register } = useFormContext();

  const [moduleId, setModuleId] = useState(null);

  return (
    <div className="space-y-6">
      <div className="tabs flex-nowrap">
        {data.map((item) => {
          const classNameActive =
            item.idModule === moduleId ? "bg-primary-600 text-white" : "";

          return (
            <a
              href="/"
              className={`tab w-full tab-bordered ${classNameActive}`}
              onClick={(e) => {
                e.preventDefault();
                setModuleId(item.idModule);
              }}
              key={item.idModule}
            >
              {item.moduleName}
            </a>
          );
        })}
      </div>

      <table className="table w-full text-gray-600">
        <thead className="border-b">
          <tr>
            <th className="bg-primary-600 text-white">No</th>
            <th className="bg-primary-600 text-white">Menu</th>
            <th className="bg-primary-600 text-white text-center">List Data</th>
            <th className="bg-primary-600 text-white text-center">
              Tambah Data
            </th>
            <th className="bg-primary-600 text-white text-center">Edit Data</th>
            <th className="bg-primary-600 text-white text-center">
              Hapus Data
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, indexModule) => {
            if (item.idModule !== moduleId) return <></>;

            const classNameCheckbox =
              "peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600";

            return (item.menuModules ?? []).map((dataModules, indexMenu) => {
              const prefixModuleFieldName = `userAccess.${indexModule}.menuModules.${indexMenu}`;

              return (
                <tr className="border-b" key={prefixModuleFieldName}>
                  <td>{indexMenu + 1}</td>
                  <td>{dataModules.menuName}</td>
                  <td className="text-center">
                    <input
                      className={classNameCheckbox}
                      type="checkbox"
                      {...register(`${prefixModuleFieldName}.canView`)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className={classNameCheckbox}
                      type="checkbox"
                      {...register(`${prefixModuleFieldName}.canCreate`)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className={classNameCheckbox}
                      type="checkbox"
                      {...register(`${prefixModuleFieldName}.canUpdate`)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className={classNameCheckbox}
                      type="checkbox"
                      {...register(`${prefixModuleFieldName}.canDelete`)}
                    />
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableModuleAccess;
