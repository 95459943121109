import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";

const Spinner = ({ className = "" }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
      }}
    >
      <CgSpinner
        className={classNames(
          ["animate-spin", "text-primary-700", "text-6xl"],
          [...className.split(" ")]
        )}
      />
    </div>
  );
};

export default Spinner;
