import React from "react";
import { Label } from "components";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const InformationForm = () => {
  const { register, control, watch } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Nama User</Label>
        <InputForm className="w-full" name="name"
          disabled />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Username/ID</Label>
        <InputForm className="w-full" name="username"
          disabled />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Email</Label>
        <InputForm className="w-full" name="email"
          disabled />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Nip</Label>
        <InputForm className="w-full" name="nip"
          disabled />
      </div>
    </div>
  );
};

export default InformationForm;
