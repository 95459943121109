import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";
import { useQuery } from "@tanstack/react-query";
import { getListGroup } from "services/GroupManagement";

const GroupManagement = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [paramsTabelLevel, setParamsTabelLevel] = useState({});

  const [listLevel, setListLevel] = useState();
  const [listLevelPagination, setListLevelPagination] = useState({
    page: 1,
    limit: 10,
    total: 7,
    previous_pages: 0,
    next_pages: 2,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["list-group", paramsTabelLevel],
    queryFn: async () => {
      const res = await getListGroup(paramsTabelLevel);
      return res?.data?.data || [];
    },
    retry: 1
  });

  useEffect(() => {

    setListLevel(data?.data.map((entry) => {
      return {
        code: entry.id,
        level_name: entry.name,
        description: entry.description,
      };
    }));

        let pagePrev = data?.currentPage - 1;
    if (pagePrev < 0) pagePrev = 1;

    let pageNext = data?.currentPage + 1;
    if (pageNext > data?.totalPages) pageNext = null;
    
    setListLevelPagination({
      page: data?.currentPage,
      limit: data?.pageSize,
      total: data?.totalCount,
      previous_pages: pagePrev,
      next_pages: pageNext,
    })
  }, [data]);
  
  const links = [
    {
      path: "/group-management",
      label: "Management Group User",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          setParamsTabelLevel({... {"generalSearch" : event.target.value}})
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const setNewLimit = ((event) => {
    setParamsTabelLevel({ "pageSize": event })
  })

  const setNewPage = ((event) => {
    setParamsTabelLevel({ "pageNumber": event })
  })

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "level_name",
        title: "Group User",
        dataIndex: "level_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "code",
        title: "Aksi",
        dataIndex: "code",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate("/group-management/form/edit", {state: {privilage : value}})}
            />
            <RiCheckboxMultipleLine
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/group-management/access`, {state: {privilage : value}})}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
      <BasicList
        links={links}
        title={"List Group User"}
        onSearch={onSearch}
        columns={tableColumns}
        onCreate={() => navigate("/group-management/form")}
        dataSource={listLevel}
        isLoading={isFetching}
        onChangePage={setNewPage}
        onChangeRowsPerPage={setNewLimit}
        pagination={listLevelPagination}
      />
  );
};

export default GroupManagement;
