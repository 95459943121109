import InternalUsers from "pages/InternalUsers";
import LevelManagement from "pages/LevelManagement";
import LevelManagementForm from "pages/LevelManagement/Form";
import DetailInternalUser from "pages/InternalUsers/Detail";
import GroupManagement from "pages/GroupManagement";
import GroupManagementForm from "pages/GroupManagement/Form";
import LevelManagementAccess from "pages/LevelManagement/DetailAccess";
import GroupManagementAccess from "pages/GroupManagement/DetailGroup";
import LevelManagementFormEdit from "pages/LevelManagement/FormEdit";
import GroupManagementFormEdit from "pages/GroupManagement/FormEdit";

import listKLUsers from "./route-list-kl-users";

const listInternalUsers = [
  {
    routeType: "private",
    path: "/list-internal-users",
    element: InternalUsers,
    exact: true,
    accessRight: {
      id: "list-internal-users",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/list-internal-users/detail",
    element: DetailInternalUser,
    exact: true,
    accessRight: {
      id: "list-internal-users",
      type: "canView"
    }
  },
]

const levelManagement = [
  {
    routeType: "private",
    path: "/level-management",
    element: LevelManagement,
    exact: true,
    accessRight: {
      id: "level-management",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/level-management/access",
    element: LevelManagementAccess,
    exact: true,
    accessRight: {
      id: "level-management",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/level-management/form",
    element: LevelManagementForm,
    exact: true,
    accessRight: {
      id: "level-management",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/level-management/form/edit",
    element: LevelManagementFormEdit,
    exact: true,
    accessRight: {
      id: "level-management",
      type: "canUpdate"
    }
  },
]

const groupManagement = [
  {
    routeType: "private",
    path: "/group-management",
    element: GroupManagement,
    exact: true,
    accessRight: {
      id: "group-management",
      type: "canView"
    }
  },
  {
    routeType: "private",
    path: "/group-management/access",
    element: GroupManagementAccess,
    exact: true,
    accessRight: {
      id: "group-management",
      type: "canUpdate"
    }
  },
  {
    routeType: "private",
    path: "/group-management/form",
    element: GroupManagementForm,
    exact: true,
    accessRight: {
      id: "group-management",
      type: "canCreate"
    }
  },
  {
    routeType: "private",
    path: "/group-management/form/edit",
    element: GroupManagementFormEdit,
    exact: true,
    accessRight: {
      id: "group-management",
      type: "canUpdate"
    }
  },
]

const routesName = [
  {
    routeType: "private",
    path: "/",
    element: InternalUsers,
    exact: true,
    accessRight: {
      id: "list-internal-users",
      type: "canView"
    }
  },
  ...listInternalUsers,
  ...listKLUsers,
  ...levelManagement,
  ...groupManagement,
];

export default routesName;
