import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";
import { useQuery } from "@tanstack/react-query";
import { deleteUserKL, getListUserKL } from "services/userKL";
import { ConfirmationModal } from "components/Modal";
import { Spinner } from "components";
import { ToastContext } from "providers/toastProvider";

const KLUsers = () => {
  const { showToastMessage } = useContext(ToastContext);
  const navigate = useNavigate();

  const [deleteableUserId, setDeleteableUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [dataSource, setDataSource] = useState();
  const [dataSourceParams, setDatasourceParams] = useState({});
  const [dataSourcePagination, setDataSourcePagination] = useState({
    page: 1,
    limit: 10,
    total: 7,
    previous_pages: 0,
    next_pages: 2,
  });

  const {
    data,
    isLoading: isLoadingListUserKL,
    refetch: refetchListUserKL,
  } = useQuery({
    queryKey: ["list-user-kl", dataSourceParams],
    queryFn: async () => {
      const res = await getListUserKL(dataSourceParams);
      return res?.data?.data || [];
    },
    retry: 1,
  });

  useEffect(() => {
    setDataSource(data?.data.map((entry) => ({ ...entry })));

    setDataSourcePagination({
      page: data?.currentPage,
      limit: data?.pageSize,
      total: data?.totalCount,
      previous_pages: data?.currentPage - 1,
      next_pages: data?.currentPage + 1,
    });
  }, [data]);

  const onSearch = debounce(
    useCallback((event) => {
      setDatasourceParams({
        ...{ generalSearch: event.target.value },
      });
    }, []),
    1000
  );

  const links = [
    {
      path: "/list-kl-users",
      label: "Data User Kementrian Lembaga",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "fullname",
        title: "Nama",
        dataIndex: "fullname",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "email",
        title: "email",
        dataIndex: "email",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "nik",
        title: "NIK",
        dataIndex: "nik",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "jabatan",
        title: "Jabatan",
        dataIndex: "jabatan",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "directorat",
        title: "Direktorat",
        dataIndex: "directorat",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "id",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                navigate(`/list-kl-users/form/edit`, {
                  state: { id: value },
                });
              }}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => setDeleteableUserId(value)}
            />
          </div>
        ),
      },
    ];
  }, [paginator?.page, paginator?.limit, navigate]);

  const setNewLimit = (event) => {
    setDatasourceParams({ ...{ pageSize: event } });
  };

  const setNewPage = (event) => {
    setDatasourceParams({
      ...{
        pageNumber: event,
      },
    });
  };

  const handleDelUser = () => {
    if (!deleteableUserId) return;

    setIsLoading(true);

    deleteUserKL({ id: deleteableUserId })
      .then(() => {
        showToastMessage(
          "Data Berhasil Dihapus!",
          "Data yang telah terhapus tidak dapat dikembalikan kembali"
        );
      })
      .catch((e) => {
        showToastMessage(
          "Data Gagal Dihapus!",
          e.response?.data?.message ?? e.message,
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteableUserId(null);

        refetchListUserKL();
      });
  };

  return (
    <>
      <BasicList
        links={links}
        title={"List User Kementrian Lembaga"}
        columns={tableColumns}
        dataSource={dataSource}
        onCreate={() => navigate("/list-kl-users/form")}
        onSearch={onSearch}
        onChangePage={setNewPage}
        onChangeRowsPerPage={setNewLimit}
        pagination={dataSourcePagination}
      />

      <ConfirmationModal
        open={deleteableUserId}
        onSubmit={handleDelUser}
        onClose={() => setDeleteableUserId(null)}
      />

      {(isLoading || isLoadingListUserKL) && <Spinner />}
    </>
  );
};

export default KLUsers;
