import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { BasicList } from "components/ListPage";
import { useQuery } from "@tanstack/react-query";
import { getListLevel } from "services/leveManagement";

const LevelManagement = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [paramsTabelLevel, setParamsTabelLevel] = useState({});
  const [listLevel, setListLevel] = useState();

  const { data } = useQuery({
    queryKey: ["list-level", paramsTabelLevel],
    queryFn: async () => {
      const res = await getListLevel(paramsTabelLevel);
      return res?.data?.data || [];
    },
    retry: 1
  });

  useEffect(() => {

    setListLevel(data?.data.map((entry) => {
      return {
        code: entry.id,
        level_name: entry.name,
        description: entry.description,
      };
    }));

  }, [data]);
  
  const links = [
    {
      path: "/level-management",
      label: "Management Level User",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          setParamsTabelLevel({... {"generalSearch" : event.target.value}})
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "level_name",
        title: "Level User",
        dataIndex: "level_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "code",
        title: "Aksi",
        dataIndex: "code",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate("/level-management/form/edit", {state: {privilage : value}})}
            />
            <RiCheckboxMultipleLine
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/level-management/access`, {state: {privilage : value}})}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
      <BasicList
        links={links}
        title={"List Level User"}
        onSearch={onSearch}
        columns={tableColumns}
        onCreate={() => navigate("/level-management/form")}
        dataSource={listLevel}
      />
  );
};

export default LevelManagement;
