import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";

import { Button, Spinner } from "components";
import { getGroupDetail, updateGroupDetail } from "services/GroupManagement";
import { ToastContext } from "providers/toastProvider";

import List from "../components/FormList";

const GroupManagementForm = () => {
  const { showToastMessage } = useContext(ToastContext);
  const { state } = useLocation();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: async () => {
      try {
        const { data } = await getGroupDetail(state.privilage);

        const groupUsers = data.data?.users.map((user) => ({
          code: user.idUser,
          jabatan: user.posisi,
          type: "internal",
          checked: true,
          ...user,
        }));

        const groupUsersKL = data.data?.userKL.map((user) => ({
          code: user.idUser,
          name: user.fullname,
          type: "kl",
          checked: true,
          ...user,
        }));

        let groupUserType = "internal";
        if (groupUsers.length <= 0 && groupUsersKL.length) {
          groupUserType = "kl";
        }

        return {
          name: data.data?.name ?? "",
          description: data.data?.description ?? "",
          groupUserType,
          groupUsers: [...groupUsers, ...groupUsersKL],
        };
      } catch (e) {
        showToastMessage(
          "Gagal memuat halaman!",
          e.response?.data?.message ?? e.message,
          "error"
        );

        navigate("/group-management");
      }
    },
  });

  const usersInGroup = methods.watch("groupUsers", []);

  const { mutateAsync: updateGroup, isPending: isLoading } = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await updateGroupDetail(someProps);
        return update;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  const links = [
    {
      path: "/group-management",
      label: "Management group User",
    },
    {
      label: "Edit group",
    },
  ];

  const onSubmit = async (data) => {
    try {
      const groupUsers = data.groupUsers
        .filter(({ type }) => type === "internal")
        .map((obj) => obj.code);

      const groupKL = data.groupUsers
        .filter(({ type }) => type === "kl")
        .map((obj) => obj.code);

      await updateGroup({
        id: state.privilage,
        name: data.name,
        description: data.description,
        groupUsers,
        groupKL,
      });

      showToastMessage("Data Berhasil Tersimpan!");

      navigate("/group-management");
    } catch (e) {
      showToastMessage(
        "Data gagal disimpan!",
        e.response?.data?.message ?? e.message,
        "error"
      );
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      <FormProvider {...methods}>
        <List links={links} title="Edit Data" />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
            type="button"
            onClick={() => methods.handleSubmit(onSubmit)()}
            disabled={!usersInGroup.length}
          >
            Submit
          </Button>
        </div>
      </FormProvider>
    </>
  );
};

export default GroupManagementForm;
