import React from "react";
import { Label } from "components";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const RegistrationForm = () => {
  const { register, control, watch } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Tanggal Registrasi</Label>
        <CustomDatepickerForm name="date"/>
      </div>
      <div className="space-y-2">
        <Label required>Pulikasi</Label>
        <div className="flex space-x-6">
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("isPublic")}
              name='isPublic'
              value="exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Aktif
            </span>
          </label>
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("isPublic")}
              name='isPublic'
              value="non-exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Tidak Aktif
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
