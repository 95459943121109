import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "./app/store";
import Routes from "./routes";
import { AnimatePresence } from "framer-motion";
import axios from "axios";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./providers/authProvider";
import { errorInterceptor, requestInterceptor, responseInterceptor } from "app/interceptors";

const persistor = persistStore(store);

const queryClient = new QueryClient();

axios.interceptors.response.use(responseInterceptor, errorInterceptor);
axios.interceptors.request.use(requestInterceptor);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AnimatePresence exitBeforeEnter>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </AnimatePresence>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
