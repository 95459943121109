import axios from "axios";
import store from "./store";

const requestInterceptor = (config) => {
  let userOidc = store.getState().oidc.oidc_user;
  userOidc = JSON.parse(userOidc);
  if (userOidc?.access_token) {
    config.headers["Authorization"] = `Bearer ${userOidc.access_token}`;
  }
  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    window.location.replace(`${window.location.origin}/logout`);
  }
  if (axios.isCancel(error)) {
    return;
  }
  return Promise.reject(error);
};

export { responseInterceptor, errorInterceptor, requestInterceptor };