import React from "react";
import PropTypes from "prop-types";

const SuccessToast = ({ message, message_description, onClose }) => {
  return (
    <div
      className={`fixed right-5 w-[30%] top-5 shadow-[0_2px_4px_rgba(0,0,0,0.2)] px-4 py-3 border-solid border-[1px] border-emerald-500 rounded-[12px]`}
      style={{ backgroundColor: "#F7FFFA", zIndex: 9999 }}
    >
      <button
        className="absolute text-lg font-semibold top-2 right-4 text-emerald-400 hover:text-emerald-500 focus:outline-none"
        onClick={onClose}
      >
        x
      </button>
      <p className={`text-emerald-400 font-semibold text-base `}>{message}</p>
      <p className={`text-black text-sm`}>{message_description}</p>
    </div>
  );
};

SuccessToast.propTypes = {
  message: PropTypes.string.isRequired,
  message_description: PropTypes.string.isRequired,
};

export default SuccessToast;
