import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';

import { ToastContext } from 'providers/toastProvider';
import {
  getAccessDetailUserKL,
  getDetailUserKL,
  updateAccessUserKL,
  updateDetailUserKL,
  getDirectorate,
} from 'services/userKL';
import { Breadcrumbs, Button, Spinner } from 'components';

import TableModuleAccess from 'components/TableModuleAccess';

import KLUsersFormFields from '../components/FormFields';

const KLUsersFormEdit = () => {
  const { showToastMessage } = useContext(ToastContext);
  const { state } = useLocation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [userId, setUserId] = useState(null);
  const [moduleAccess, setModuleAccess] = useState([]);

  const methods = useForm({
    defaultValues: async () => {
      try {
        setIsLoading(true);

        const { data: respUserKL } = await getDetailUserKL(state.id);

        const { data } = await getAccessDetailUserKL(respUserKL.data.idUser);

        setUserId(respUserKL.data.idUser);
        setModuleAccess(data.data.userAccess);

        setIsLoading(false);

        return {
          fullname: data.data.fullname ?? '',
          nik: data.data.nik ?? '',
          email: data.data.email ?? '',
          jabatan: data.data.jabatan ?? '',
          directorat:
            { value: data.data.directorat, label: data.data.directorat } ?? {},
          userAccess: data.data.userAccess,
        };
      } catch (error) {
        setIsLoading(false);

        showToastMessage(
          'Gagal memperoleh data user',
          error.response?.data?.message ?? error.message,
          'error'
        );

        return {
          fullname: '',
          nik: '',
          email: '',
          jabatan: '',
          directorat: '',
          userAccess: [],
        };
      }
    },
  });

  const { data } = useQuery({
    queryKey: ['list-group'],
    queryFn: async () => {
      const res = await getDirectorate({
        limit: 100,
        page: 1,
      });

      const options = res?.data?.data?.data.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      return options || [];
    },
    retry: 1,
  });

  const mutationUpdateUserKL = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await updateDetailUserKL(someProps);
        return update;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  const mutationUpdateUserKLAccess = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await updateAccessUserKL(someProps);
        return update;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  const links = [
    {
      path: '/list-kl-users',
      label: 'Data User Kementrian Lembaga',
    },
    {
      label: 'Ubah Data User Kementrian Lembaga',
    },
  ];

  const onSubmit = async (data) => {
    try {
      await mutationUpdateUserKL.mutateAsync({
        id: state.id,
        fullname: data.fullname,
        nik: data.nik,
        email: data.email,
        jabatan: data.jabatan,
        directorat: data.directorat.value,
      });

      await mutationUpdateUserKLAccess.mutateAsync({
        id: userId,
        userAccess: data.userAccess.filter((ua) => !!ua),
      });

      showToastMessage('Data Berhasil Diperbarui!', '');

      navigate('/list-kl-users');
    } catch (error) {
      showToastMessage(
        'Gagal Memperbarui Data!',
        error.response?.data?.message ?? error.message,
        'error'
      );
    }
  };

  return (
    <>
      {(isLoading ||
        mutationUpdateUserKL.isPending ||
        mutationUpdateUserKLAccess.isPending) && <Spinner />}

      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="pt-8">
            <KLUsersFormFields title="Ubah Data" directorateOptions={data} />
          </div>

          <div className="my-4">
            <TableModuleAccess data={moduleAccess} />
          </div>

          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default KLUsersFormEdit;
