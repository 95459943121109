import { RadioButtonForm } from 'components';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const RadioGroupForm = ({ name, items }) => {
  const {
    control,
  } = useFormContext();

  return (
    <>
    {/* // <Controller
    //   name={name}
    //   control={control}
    //   defaultValue=""
    //   render={({ field, fieldState: {invalid, error} }) => (
    //     <div className="flex space-x-6">
    //       {items.map((item, key) => (
    //         <label className="flex space-x-2 items-center" key={key}>
    //           <RadioButtonForm name={name} value={item.value} />
    //           <span className="text-sm font-semibold text-gray-700">{item.label}</span>
    //         </label>
    //       ))}
    //     </div>
    //   )}
    // /> */}
    </>
  )
}

export default RadioGroupForm