import React from 'react'
import { Input } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

const InputForm = ({
  name,
  ...props
}) => {
  const {
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field: { onChange, value, onBlur }, fieldState: {invalid, error} }) => (
        <div className="w-full">
          <Input {...props} value={value} onChange={onChange} isError={invalid} />
          {invalid && <div className="text-xs text-error-600 mt-2">{error?.message}</div>}
        </div>
      )}
    />
  )
}

export default InputForm