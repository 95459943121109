import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const GroupForm = () => {
  const { register, control, watch } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Group User</Label>
        <InputForm disabled className="w-full" name="group" />
      </div>
    </div>
  );
};

export default GroupForm;
