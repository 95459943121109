import axios from "axios";
import { config } from "app/config";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getListGroup= (params) => {
  return axios.post(
    `${config.BASE_URL}/Group/datatable`,
    params,
    { headers }
  );
};

export const postGroup= (params) => {
  return axios.post(
    `${config.BASE_URL}/Group`,
    params,
    { headers }
  );
};

export const getGroupDetail= (params) => {
  return axios.get(
    `${config.BASE_URL}/Group/${params}`,
    { headers }
  );
};

export const updateGroupDetail = (params) => {
  return axios.put(`${config.BASE_URL}/Group`, params, {
    headers,
  });
};

export const getDetailPrivilage= (params) => {
  return axios.get(
    `${config.BASE_URL}/Group/privilege/${params}`,
    { headers }
  );
};

export const updateDetailPrivilage = (params) => {
  return axios.put(
    `${config.BASE_URL}/Group/privilege`,
    params,
    {
      headers,
    }
  );
};