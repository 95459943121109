import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';

import { ToastContext } from 'providers/toastProvider';
import { Breadcrumbs, Button, Spinner } from 'components';
import { createUserKL, getDirectorate } from 'services/userKL';

import KLUsersFormFields from '../components/FormFields';

const KLUsersForm = () => {
  const { showToastMessage } = useContext(ToastContext);
  const { parameter } = useState({
    limit: 100,
    page: 1,
  });

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      fullname: '',
      nik: '',
      email: '',
      jabatan: '',
      directorat: '',
    },
  });

  const { data } = useQuery({
    queryKey: ['list-group'],
    queryFn: async () => {
      const res = await getDirectorate(parameter);

      const options = res?.data?.data?.data.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      return options || [];
    },
    retry: 1,
  });

  const mutation = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await createUserKL(someProps);
        return update;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  const links = [
    {
      path: '/list-kl-users',
      label: 'Data User Kementrian Lembaga',
    },
    {
      path: '/list-kl-users/form',
      label: 'Tambah User Kementrian Lembaga',
    },
  ];

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const payload = {
        fullname: data.fullname,
        nik: data.nik,
        email: data.email,
        jabatan: data.jabatan,
        directorat: data.directorat.value,
        directoratCode: '-',
      };

      const { data: respCreateUserKL } = await mutation.mutateAsync(payload);

      setIsLoading(false);

      navigate('/list-kl-users/form/edit', {
        state: { id: respCreateUserKL.data },
      });

      showToastMessage(
        'Data Berhasil Tersimpan!',
        'Lanjutkan atur menu akses untuk user'
      );
    } catch (e) {
      setIsLoading(false);

      showToastMessage(
        'Data gagal disimpan!',
        e.response?.data?.message ?? e.message,
        'error'
      );
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="pt-8">
            <KLUsersFormFields title="Tambah Data" directorateOptions={data} />
          </div>

          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default KLUsersForm;
