import React, { forwardRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Datepicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import classNames from "classnames";

import "react-datepicker/dist/react-datepicker.css";

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, onClear, isError } = props;
  const defaultClassName =
    "flex flex-1 items-center !px-4 !py-2 border-[1px] border-gray-200 rounded-md bg-white w-full text-sm text-gray-400";
  return (
    <div
      className={classNames(...defaultClassName.split(" "), {
        [`!border-error-500`]: isError,
      })}
    >
      <input
        value={value}
        ref={ref}
        className="!p-0 w-full outline-none border-white"
        readOnly
        onClick={onClick}
      />
      {value ? (
        <IoMdCloseCircle
          className="text-gray-400"
          size={20}
          cursor="pointer"
          onClick={onClear}
        />
      ) : (
        <AiOutlineCalendar
          className="text-gray-400"
          size={20}
          cursor="pointer"
          onClick={onClick}
        />
      )}
    </div>
  );
});

const CustomDatepickerForm = ({
  name,
  placeholder,
  showIcon = true,
  disabled,
  onClear,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <Datepicker
            disabledKeyboardNavigation
            placeholderText={placeholder}
            value={value}
            selected={value}
            onChange={onChange}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInput onClear={onClear} isError={invalid} placeholderText={placeholder} />}
            {...props}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default CustomDatepickerForm;
