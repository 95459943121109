import { debounce } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, CardHeader, TypeBadge } from "components";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getLevelUserList, postLevel } from "services/leveManagement";
import List from "../components/FormList"
import { ToastContext } from "providers/toastProvider";

const LevelManagementForm = () => {
  const navigate = useNavigate();
  const { showToastMessage } = useContext(ToastContext);

  const [keyword, setKeyword] = useState(undefined);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pagination, setPagination] =
    useState({
      page: 1,
      limit: 10,
      total: 7,
      previous_pages: 0,
      next_pages: 2,
    });
  const [paramsTableListUserInternal, setParamsTableListUserInternal] =
    useState({});

  const methods = useForm({
    defaultValues: {
      name: "",
      description: "",
      levelUserType: "internal",
      levelUsers: [],
    },
  });

  const { data } = useQuery({
    queryKey: ["list-user-internal-create", paramsTableListUserInternal],
    queryFn: async () => {
      const res = await getLevelUserList();
      return res?.data?.data || [];
    },
    retry: 1,
  });

  const mutation = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await postLevel(someProps);
        return update;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  });

  const links = [
    {
      path: "/level-management",
      label: "Management Level User",
    },
    {
      path: "/level-management/form",
      label: "Tambah Level",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          setParamsTableListUserInternal({
            ...{ generalSearch: event.target.value },
          });
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const setNewLimit = (event) => {
    setParamsTableListUserInternal({ ...{ pageSize: event } });
  };

  const setNewPage = (event) => {
    setParamsTableListUserInternal({
      ...{
        pageNumber: event,
      },
    });
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "name",
        title: "Nama User",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "directorat",
        title: "directorat",
        dataIndex: "directorat",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "divisi",
        title: "divisi",
        dataIndex: "divisi",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "nip",
        title: "NIP",
        dataIndex: "nip",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  const onSubmit = async (data) => {
    try {
      const levelUsers = data.levelUsers
        .filter(({ type }) => type === "internal")
        .map((obj) => obj.code);
      const levelKL = data.levelUsers
        .filter(({ type }) => type === "kl")
        .map((obj) => obj.code);

      const payload = {
        name: data.name,
        description: data.description,
        levelUsers,
        levelKL,
      }

      mutation.mutate(payload);
      navigate('/level-management')

      showToastMessage("Data Berhasil Tersimpan!");
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
      <List links={links} title="Tambah Data" />
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => methods.handleSubmit(onSubmit)()}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
          >
            Submit
          </Button>
        </div>
      </FormProvider>
    </>
  );
};

export default LevelManagementForm;
