import axios from 'axios';
import { config } from 'app/config';

const headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true,
};

const directorateBaseUrl = 'https://bpldh-api-v2.groot.id';

export const getListUserKL = (params) => {
  return axios.post(`${config.BASE_URL}/EmployeeKL/datatable`, params, {
    headers,
  });
};

export const getDetailUserKL = (params) => {
  return axios.get(`${config.BASE_URL}/EmployeeKL/${params}`, { headers });
};

export const createUserKL = (params) => {
  return axios.post(`${config.BASE_URL}/EmployeeKL`, params, { headers });
};

export const updateDetailUserKL = (params) => {
  return axios.put(`${config.BASE_URL}/EmployeeKL`, params, { headers });
};

export const getAccessDetailUserKL = (params) => {
  return axios.get(`${config.BASE_URL}/User/KL/${params}`, { headers });
};

export const updateAccessUserKL = (params) => {
  return axios.put(`${config.BASE_URL}/User/access/KL`, params, { headers });
};

export const deleteUserKL = (params) => {
  return axios.delete(`${config.BASE_URL}/EmployeeKL`, { headers, params });
};

// list directorate
export const getDirectorate = (params) => {
  return axios.get('/api/dana-program/v1/cms/institutional-ministry/list', {
    headers,
    params,
    baseURL: directorateBaseUrl,
  });
};
