import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import store from "app/store";

const Header = () => {
  const oidc_user = store.getState().oidc.oidc_user;
  const user = JSON.parse(oidc_user);
  
  return (
    <nav
      className="navbar border-b top-0"
      style={{ zIndex: 99 }}
    >
      <div className="flex-1">
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src={user.profile.UrlFoto} />
              </div>
            </label>
            <label tabIndex="1">{user.profile.Name}</label>
          </div>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <AuthConsumer>
              {({ logout }) => {    
                  return <li onClick={() => logout()}> <a>Logout</a></li>;
              }}
            </AuthConsumer>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
