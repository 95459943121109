import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";
import { TypeBadge } from "components";
import { useQuery } from "@tanstack/react-query";
import { getListUserInternal } from "services/userInternal";
import { SuccessToast } from "components/Toast";
import { ToastContext } from "providers/toastProvider";

const InternalUsers = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(undefined);
  const { toastMessage, toastDescription, showToast, hideToastMessage } =
    useContext(ToastContext);
  const [listUsersInternalPagination, setListUsersInternalPagination] = useState({
    page: 1,
    limit: 10,
    total: 7,
    previous_pages: 0,
    next_pages: 2,
  });
  const [listUsersInternal, setListUsersInternal] = useState();
  const [paramsTableListUserInternal, setParamsTableListUserInternal] = useState({});

  const { data } = useQuery({
    queryKey: ["list-user-internal", paramsTableListUserInternal],
    queryFn: async () => {
      const res = await getListUserInternal(paramsTableListUserInternal);
      return res?.data?.data || [];
    },
    retry: 1
  });

  useEffect(() => {

    setListUsersInternal(data?.data.map((entry) => {
      return {
        code: entry.idUser,
        jabatan: entry.jabatan,
        name: entry.name,
        nik: entry.nik,
        nip: entry.nip,
        email: entry.emailPegawai,
        status_user: entry.status ? {
          id: 1,
          name: "Aktif",
        } : {
          id: 2,
          name: "Tidak Aktif",
        },
      };
    }));

    setListUsersInternalPagination({
      page: data?.currentPage,
      limit: data?.pageSize,
      total: data?.totalCount,
      previous_pages: data?.currentPage - 1,
      next_pages: data?.currentPage + 1,
    })

  }, [data]);

  const links = [
    {
      path: "/list-internal-users",
      label: "Data User Internal",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          setParamsTableListUserInternal({... {"generalSearch" : event.target.value}})
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const setNewLimit = ((event) => {
    setParamsTableListUserInternal({...
      {"pageSize": event}
    })
  })

  const setNewPage = ((event) => {
    setParamsTableListUserInternal({...{
      "pageNumber": event}
    })
  })

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "nik",
        title: "NIK",
        dataIndex: "nik",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "name",
        title: "Nama User",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "email",
        title: "email",
        dataIndex: "email",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "jabatan",
        title: "Jabatan",
        dataIndex: "jabatan",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "status_user",
        title: "Status User",
        dataIndex: "status_user",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <TypeBadge data={value} />
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "code",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/list-internal-users/detail`, {state: {user_id : value}})}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <>
    {showToast && (
      <SuccessToast
        message={toastMessage}
        message_description={toastDescription}
        onClose={hideToastMessage}
      />
    )}
    <BasicList
      links={links}
      title={"List User Internal"}
      onSearch={onSearch}
      columns={tableColumns}
      dataSource={listUsersInternal}
      onChangePage={setNewPage}
      onChangeRowsPerPage={setNewLimit}
      pagination={listUsersInternalPagination}
    />
    </>
  );
};

export default InternalUsers;
