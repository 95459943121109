import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";

import { Button, Spinner } from "components";
import { getLevelDetail, updateLevelDetail } from "services/leveManagement";
import { ToastContext } from "providers/toastProvider";

import List from "../components/FormList";

const LevelManagementFormEdit = () => {
  const { showToastMessage } = useContext(ToastContext);
  const { state } = useLocation();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: async () => {
      try {
        const { data } = await getLevelDetail(state.privilage);

        const levelUsers = data.data?.users.map((user) => ({
          code: user.idUser,
          jabatan: user.posisi,
          type: "internal",
          checked: true,
          ...user,
        }));

        const levelUsersKL = data.data?.userKL.map((user) => ({
          code: user.idUser,
          name: user.fullname,
          type: "kl",
          checked: true,
          ...user,
        }));

        let levelUserType = "internal";
        if (levelUsers.length <= 0 && levelUsersKL.length) {
          levelUserType = "kl";
        }

        return {
          name: data.data?.name ?? "",
          description: data.data?.description ?? "",
          levelUserType,
          levelUsers: [...levelUsers, ...levelUsersKL],
        };
      } catch (e) {
        showToastMessage(
          "Gagal memuat halaman!",
          e.response?.data?.message ?? e.message,
          "error"
        );

        navigate("/level-management");
      }
    },
  });

  const usersInLevel = methods.watch("levelUsers", []);

  const { mutateAsync: updateLevel, isPending: isLoading } = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await updateLevelDetail(someProps);
        return update;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  const links = [
    {
      path: "/level-management",
      label: "Management level User",
    },
    {
      label: "Edit level",
    },
  ];

  const onSubmit = async (data) => {
    try {
      const levelUsers = data.levelUsers
        .filter(({ type }) => type === "internal")
        .map((obj) => obj.code);

      const levelKL = data.levelUsers
        .filter(({ type }) => type === "kl")
        .map((obj) => obj.code);

      await updateLevel({
        id: state.privilage,
        name: data.name,
        description: data.description,
        levelUsers,
        levelKL,
      });

      showToastMessage("Data Berhasil Tersimpan!");

      navigate("/level-management");
    } catch (e) {
      showToastMessage(
        "Data gagal disimpan!",
        e.response?.data?.message ?? e.message,
        "error"
      );
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      <FormProvider {...methods}>
        <List links={links} title="Edit Data" />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
            type="button"
            onClick={() => methods.handleSubmit(onSubmit)()}
            disabled={!usersInLevel.length}
          >
            Submit
          </Button>
        </div>
      </FormProvider>
    </>
  );
};

export default LevelManagementFormEdit;
