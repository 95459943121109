import React, { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { debounce } from "lodash";

import { BasicList } from "components/ListPage";
import TopFormList from "../TopFormList";

const FormList = ({ links = [], title = "" }) => {
  const { getValues, setValue, watch } = useFormContext();

  const userType = watch("groupUserType");
  const usersInGroup = watch("groupUsers", []);

  const [userKeyword, setUserKeyword] = useState();

  const usersInGroupFiltered = useMemo(() => {
    return usersInGroup.filter(({ name, nip, nik, type }) => {
      let isMatchedKeyword = true;
      if (userKeyword) {
        const keyword = userKeyword.toLowerCase();

        isMatchedKeyword = name.toLowerCase().includes(keyword);

        if (type === "internal") {
          isMatchedKeyword = isMatchedKeyword || nip.includes(keyword);
        }

        if (type === "kl") {
          isMatchedKeyword = isMatchedKeyword || nik.includes(keyword);
        }
      }

      return type === userType && isMatchedKeyword;
    });
  }, [userType, userKeyword, usersInGroup]);

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  }, []);

  const onSearch = debounce(
    (event) => setUserKeyword(event.target.value),
    1000
  );

  const handleUnselectUser = useCallback(
    (idUser) => {
      const currentUsersInGroup = getValues("groupUsers");

      setValue("groupUsers", [
        ...currentUsersInGroup.filter(({ code }) => code !== idUser),
      ]);
    },
    [getValues, setValue]
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "name",
        title: "Nama User",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
            <br />
            <em>{userType === "internal" ? data.nip : data.nik}</em>
          </div>
        ),
      },
      {
        id: "directorat",
        title: "Direktorat",
        dataIndex: "directorat",
        width: 540,
        className: "overflow-hidden text-ellipsis align-top",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {userType === "internal" ? (
              <>
                {value}
                <br />
                <em>{data.divisi}</em>
              </>
            ) : (
              value
            )}
          </div>
        ),
      },
      {
        id: "jabatan",
        title: "Jabatan",
        dataIndex: "jabatan",
        width: 540,
        className: "overflow-hidden text-ellipsis align-top",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "code",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 w-full">
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => handleUnselectUser(value)}
            />
          </div>
        ),
      },
    ];
  }, [userType, paginator, handleUnselectUser]);

  return (
    <BasicList
      links={links}
      onSearch={onSearch}
      columns={tableColumns}
      dataSource={usersInGroupFiltered}
      topFormList={<TopFormList title={title} />}
    />
  );
};

export default FormList;
