import axios from "axios";
import { config } from "app/config";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const submitGeneralProfile = (params) => {
  return axios.post(
    `${config.BASE_URL}/v1/general-profile/submit`,
    params,
    {
      headers,
    }
  );
};

export const getListUserInternalWEmployeeInfo = (params) => {
  return axios.post(`${config.BASE_URL}/Employee/datatable`, params, {
    headers,
  });
};

export const getListUserInternal= (params) => {
  return axios.post(
    `${config.BASE_URL}/Employee/datatable`,
    params,
    { headers }
  );
};

export const getDetailUserInternal= (params) => {
  return axios.get(
    `${config.BASE_URL}/User/internal/${params}`,
    { headers }
  );
};

export const updateDetailUserInternal = (params) => {
  console.log(params)
  return axios.put(
    `${config.BASE_URL}/User/internal`,
    params,
    {
      headers,
    }
  );
};