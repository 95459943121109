export const ADD_OIDC_USER = 'ADD_OIDC_USER'
export const REMOVE_OIDC_USER = 'REMOVE_OIDC_USER'


export const addOidcUser = (user) => {
  return {
    type: ADD_OIDC_USER,
    payload:user
  }
}

export const removeOidcUser = () => {
  return {
    type: REMOVE_OIDC_USER
  }
}