import { Button, TitleText } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const FormModal = ({ title = "", open, onClose, onSubmit, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl w-1/2"
            initial={{ scale: 0 }}
            animate={{ scale: 1, animation: 10 }}
            exit={{ scale: 0 }}
          >
            <div className="flex flex-col py-4 space-y-4">
              <div className="flex-1 flex items-center justify-between px-8">
                <TitleText className="">{title}</TitleText>
                <CgClose
                  className="text-xl cursor-pointer"
                  onClick={() => onClose(false)}
                />
              </div>
              <div className="flex-1 border-t max-h-[400px] overflow-y-scroll px-8 py-4">{children}</div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default FormModal;
